<template>
  <div>
    <NotificationFilter @apply="getAllNotificationsList" />
    <NotificationReminderContainer
      :update-pagination="updatePagination"
      :is-loading-component="isLoadingComponent"
      :notification-actions="notificationActions"
      @update="typeAction"
      @filterRecord="filterRecord"
    />
    <!-- SCHEDULED NOTIFICATION -->
    <ScheduledNotificationCreateUpdate
      v-if="scheduleModel"
      :show-model="scheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :concerned="currentActionObject.concernedList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      @handleClick="toogleScheduleModel"
      @update="filterRecord"
    />
    <!-- UNSCHEDULED NOTIFICATION -->
    <UnScheduledNotificationCreateUpdate
      v-if="unScheduleModel"
      :show-model="unScheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      module="student"
      @handleClick="toggleUnScheduleModel"
      @update="updateNotificationsList"
      @addNotificationAlert="addNotification"
    />
  </div>
</template>

<script>
import ScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import UnScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationReminderContainer from '@/src/components/Settings/notifications/NotificationReminderContainer.vue'
import { paginationRangeHandler } from '@components/BaseComponent/pagination.util.js'
import NotificationFilter from '@views/settings/notifications/attendance/campus/CampusAttendanceNotificationFilters.vue'
import NOTIFICATION_PATHS, {
  NOTIFICATION_RECIEVERS,
} from '@src/constants/notification-constants.js'
/* Utils or mixins */
import { mapActions } from 'vuex'
import notificationMixin from '@src/mixins/notification-mixin.js'
import studentsData from '@src/router/views/student/students.json'
import { getNotificationsOptionsList } from '@utils/notifications'
export default {
  components: {
    ScheduledNotificationCreateUpdate,
    UnScheduledNotificationCreateUpdate,
    NotificationReminderContainer,
    NotificationFilter,
  },
  mixins: [notificationMixin],
  data() {
    return {
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.STUDENT,
      },
      commonReceiversList: [
        NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
        NOTIFICATION_RECIEVERS.CONCERNED_STUDENT,
        NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_MAIN_GUARDIAN,
        NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_GUARDIANS,
        NOTIFICATION_RECIEVERS.SPECIFIC_TEACHER,
      ],
      optionList: ['edit_student', 'enroll_student'],
    }
  },

  methods: {
    setActionList() {
      this.notificationActions = {
        add_student: studentsData.STUDENT_NOTIFICATION_ACTIONS.ADD_STUDENT,
        edit_student: studentsData.STUDENT_NOTIFICATION_ACTIONS.EDIT_STUDENT,
        enroll_student: studentsData.STUDENT_NOTIFICATION_ACTIONS.ENROLL_STUDENT,
        // Commented code for use later

        // promote_student: studentsData.STUDENT_NOTIFICATION_ACTIONS.PROMOTE_STUDENT,
        // passout_student: studentsData.STUDENT_NOTIFICATION_ACTIONS.PASSOUT_STUDENT,
      }
    },
    setConcernedAndOptionsList() {
      this.optionList.forEach((option) => {
        this.notificationActions[option].optionsList = getNotificationsOptionsList(
          this.commonReceiversList,
        )
      })
      this.notificationActions.add_student.optionsList = getNotificationsOptionsList([
        NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
        NOTIFICATION_RECIEVERS.SPECIFIC_TEACHER,
      ])
    },

    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'NOTIFICATION_REMINDER',
          buttons: [
            {
              title: 'STUDENT_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_STUDENT',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_student
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_STUDENT',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_student
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'ENROLL_STUDENT',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.enroll_student
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                // Commented code for use later

                // {
                //   title: 'PROMOTE_STUDENT',
                //   action: {
                //     func: () => {
                //       this.currentActionObject = this.notificationActions.promote_student
                //       this.toggleUnScheduleModel()
                //     },
                //   },
                //   payload: {},
                // },
                // {
                //   title: 'PASSOUT_STUDENT',
                //   action: {
                //     func: () => {
                //       this.currentActionObject = this.notificationActions.passout_student
                //       this.toggleUnScheduleModel()
                //     },
                //   },
                //   payload: {},
                // },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>
